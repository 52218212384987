import  UserService  from '../../helpers/authservice/user.service';
import router from '../../router/index'

export const state = {
    user:null,
    permissions:[],
    roles:[]
}

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });

        UserService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                },

                error => {

                    commit('loginFailure', error);
                    console.log("dispatch notification");
                    dispatch('notification/error', error, { root: true });
                    
                }
            );
    },
    // Logout the user
    logout({ commit }) {
        UserService.logout();
        commit('logout');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        UserService.register(user)
            .then(
                user => {

                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });
                    router.push('/login');

                },
                error => {
                    commit('registerFailure', error);
                    console.log("dispatch notif");
                    dispatch('notification/error', error, { root: true });
                }
            );
    },

    getProfile({commit}){
        // TBD Get user data and use that to get  current auth user 
        return  UserService.verifyUser().then(
            data => {
                commit ('loginSuccess', data.user);
                commit ('SET_PERMISSIONS', data.permissions);
                commit ('SET_ROLES', data.role);
                return Promise.resolve(data.user);
            }, error => {
                commit('AssetsFailed');
                return Promise.reject(error);
            }
        );
    },
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    SET_PERMISSIONS(state, permissions) {
        state.permissions = permissions;
    },
    SET_ROLES(state, roles) {
        state.roles = roles;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

export const getters = {

    getCurrentUser(state){
        // console.log(state.user);
        return state.user;
    },

    get_user_permissions(state){
        return state.permissions
    },
    get_user_role(state){
        return state.role
    },

    hasPermission: (state) => (permission) => {

        if (!state.user || !state.permissions) {

          return false;

        }

        // return state.permissions.includes(permission);
        return state.permissions.some(userPermission => userPermission.name === permission);
      },
    hasRole: (state) => (roleName) => {
        
        if (!state.user || !state.roles) {

          return false;
          
        }

        // return state.roles.includes(role);
        // console.log(state.roles);
        return state.roles.some(role => role.name === roleName);
      },
}

