<template>
  <div id="app">
    <router-view></router-view>

    <div v-if="loading" class="loader">
      <div class="">
            <img src="@/assets/images/logo-sm.png" alt="" class="profile-wid-img" />
        </div>
      <b-spinner variant="info" label="Loading..."></b-spinner>
      <p>Loading getting information together...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      loading: false
    }
  },
  created() {
    const user = sessionStorage.getItem('ENSPSID');

    if (user) {
      this.loading = true;
      const promises = [
        // this.$store.dispatch('user/getPermissions'),
        // this.$store.dispatch('authfack/getProfile')
      ];

      Promise.all(promises)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style>
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Ensure loader covers full screen */
}
</style>
