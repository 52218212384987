// let endpoint = 'http://127.0.0.1:8003/api'

// let endpoint = 'https://api.keldenuniversity.org/api'

let endpoint = 'https://payamm.mfuhusa.org/api'

// const endpoint = process.env.VUE_APP_API_ENDPOINT;

export default endpoint

