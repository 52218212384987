import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("../views/account/register.vue"),
  //   meta: {
  //     title: "Register",
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "dashboard",
    meta: { title: "Dashboard", authRequired: false },
    component: () => import("../pages/dashboard.vue"),
  },
  {
    path: "/otp",
    name: "otp verification",
    meta: {
      title: "OTP Verification",
    },
    component: () => import("../views/account/OTP.vue"),
  },
  {
    name: "resetPassword",
    path: "/resetpassword",
    meta: {
      title: "Reset Password",
    },
    component: () => import("../views/account/reset-password.vue"),
  },


  {
    path: "/settings",
    name: "settings",
    meta: { title: "Settings", authRequired: false },
    component: () => import("../pages/settings/settings.vue"),
  },
  {
    path: "/product-types",
    name: "Product Types",
    meta: { title: "Product Types", authRequired: false },
    component: () => import("../pages/settings/product-types.vue"),
  },
  {
    path: "/app-settings",
    name: "application settings",
    meta: { title: "Application Settings", authRequired: false },
    component: () => import("../pages/settings/app-settings.vue"),
  },
  {
    path: "/deposits",
    name: "deposits",
    meta: { title: "Deposit", authRequired: false },
    component: () => import("../pages/transactions/deposits.vue"),
  },
  {
    path: "/deposit-request",
    name: "deposit-request",
    meta: { title: "Deposit Request", authRequired: false },
    component: () => import("../pages/transactions/deposit-request.vue"),
  },
  {
    path: "/wallet-transaction",
    name: "wallet transactions",
    meta: { title: "Transactions", authRequired: false },
    component: () => import("../pages/transactions/wallet_transactions.vue"),
  },
  {
    path: "/transactions",
    name: "transactions",
    meta: { title: "Transactions", authRequired: false },
    component: () => import("../pages/transactions/transactions.vue"),
  },
  {
    path: "/transaction-single/:id",
    name: "transaction-single",
    meta: { title: "Transactions Single", authRequired: false },
    component: () => import("../pages/transactions/transaction-single.vue"),
  },
  {
    path: "/withdrawals",
    name: "withdrawals",
    meta: { title: "Withdrawals", authRequired: false },
    component: () => import("../pages/transactions/withdrawals.vue"),
  },

  {
    path: "/disputes",
    name: "disputes",
    meta: { title: "Disputes", authRequired: false },
    component: () => import("../pages/disputes/disputes.vue"),
  },
  {
    path: "/dispute-single/:id",
    name: "dispute-single",
    meta: { title: "Dispute Single", authRequired: false },
    component: () => import("../pages/disputes/dispute-single.vue"),
  },
  {
    path: "/dispute-chat/:transaction_id",
    name: "dispute-chat",
    meta: { title: "Dispute Chat", authRequired: false },
    component: () => import("../pages/disputes/Disputeschat.vue"),
  },
  {
    path: "/unresolved-disputes",
    name: "unresolved-disputes",
    meta: { title: "Unresolved Disputes", authRequired: false },
    component: () => import("../pages/disputes/unresolved-disputes.vue"),
  },
  {
    path: "/my-disputes",
    name: "my-disputes",
    meta: { title: "My Disputes", authRequired: false },
    component: () => import("../pages/disputes/MyDisputes.vue"),
  },
  {
    path: "/users",
    name: "users",
    meta: { title: "Users", authRequired: false },
    component: () => import("../pages/users/users.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    meta: { title: "Admin", authRequired: false },
    component: () => import("../pages/users/admin.vue"),
  },
  {
    path: "/user/:id",
    name: "user single",
    meta: { title: "User Single", authRequired: false },
    component: () => import("../pages/users/user-single.vue"),
  },
  {
    path: "/kyc3-single/:id",
    name: "kyc3-single",
    meta: { title: "KYC3 single", authRequired: false },
    component: () => import("../pages/users/kyc/kyc3view.vue"),
  },
  {
    path: "/kyc3-requests",
    name: "kyc3-requests",
    meta: { title: "KYC3 Requests", authRequired: false },
    component: () => import("../pages/users/kyc3-requests.vue"),
  },
  {
    path: "/kyc2-requests",
    name: "kyc2-requests",
    meta: { title: "KYC2 Requests", authRequired: false },
    component: () => import("../pages/users/kyc2-requests.vue"),
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: false,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/403',
    name: '403',
    meta: {
      title: "Error 403", authRequired: false,
    },
    component: () => import('../views/auth/errors/403')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: false,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: false,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/auth/404-cover',
    name: '404-cover',
    meta: {
      title: "Error 404", authRequired: false,
    },
    component: () => import('../views/auth/errors/404-cover')
  },
  {
    path: '/auth/ofline',
    name: 'ofline',
    meta: {
      title: "Offline", authRequired: false,
    },
    component: () => import('../views/auth/errors/ofline')
  },
  {
    path: '/auth/logout-basic',
    name: 'logout-basic',
    meta: {
      title: "Logout", authRequired: false,
    },
    component: () => import('../views/auth/logout/basic')
  },
  {
    path: '/auth/logout-cover',
    name: 'logout-cover',
    meta: {
      title: "Logout", authRequired: false,
    },
    component: () => import('../views/auth/logout/cover')
  },

];