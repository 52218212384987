// import { authHeader } from '.-header';
import api from '../api'

import axios from 'axios'

import endpoint from '../endpoint';
import router from '../../router';

class UserService{

   async login(email, password) {
    console.log("this is the login  reached");

      return await  axios.post(`${endpoint}/admin/login`, {email,password},{headers: { 'Content-Type': 'application/json' }}).then(
        response => {
            
        if (response.data.access_token) {
            sessionStorage.setItem('ENSPSID', response.data.access_token);
            sessionStorage.setItem('user',  JSON.stringify(response.data.data));
        }
        return response.data;
    })
}

    async logout() {
        return await api.post('/logout').then(
            response => {
                localStorage.removeItem('user');
                sessionStorage.removeItem('ENSPSID')
                return response.data
            }
        );
    }

    async verifyUser(){
        return await  api.get(`${endpoint}/verify_token`,{headers: { 'Content-Type': 'application/json' }}).then(
            response => {

                // store this token and refresh token 
            return response.data;
        }).catch(() =>{
            router.push('/login')
        })
    }

    async createUser(user) {
        console.log(user);
        return await api.post(`/add_admins`, {
            username:user.name,
            email:user.email,
            role:"admin",
            password:"123"
        },{headers: { 'Content-Type': 'application/json' }}).then(
            response =>{
                response.data
            }
        )
    }

    async editUser(user) {
        return await api.put(`/update-user/${user.id}`, {
            name:user.name,
            email:user.email,
            school_id:user.school,
            campus_id:user.campus,
            position:user.position,
            status:user.status,
            role_id:user.role
        }).then(
            response =>{
                response.data
            }
        )
    }


    async forgotPassword(email){
        return await  axios.post(`${endpoint}/forgot-password`, {email},{headers: { 'Content-Type': 'application/json' }}).then(
            response => {
    
            // console.log(response.data)
            return response.data;
        })
    }


    async verifyOTP(code){
        return await  axios.post(`${endpoint}/checkCode`, {code:code.toString()},{headers: { 'Content-Type': 'application/json' }}).then(
            response => {
                localStorage.setItem('enspsid', JSON.stringify(code));
            return response.data;
        })
    }

    async resetPassword(password){
        let code = localStorage.getItem('enspsid');
        return await  axios.post(`${endpoint}/passwordReset`, {code:code.toString(),password:password},{headers: { 'Content-Type': 'application/json' }}).then(
            response => {
            localStorage.removeItem('enspsid');
            return response.data;
        })
    }
    async changePassword(form){
        return await  api.post(`/change-password`, {
            old_password:form.old,
            password:form.new
        }).then(
            response => {
            return response.data;
        })
    }

    async getAllUsers() {
        return await api.get('/get_users').then().then(
            response => {
                return response.data
            }
        )
    }
    async getAdmins() {
        return await api.get('/get_admins').then().then(
            response => {
                return response.data
            }
        )
    }

    async getUser(id){
        return await api.get(`/user/${id}`,{id}).then(
            response => {
                return response.data
            }
        )
    }
    async deactivateUser(id){
        return await api.post(`/deactivate_user/${id}`).then(
            response => {
                return response.data
            }
        )
    }

    

    async getRoles(){
        return await api.get('/roles').then(
            response =>{
                return response.data
            }
        )
    }

    async addUserPermission(permission){
        return await api.post('/add-permissions',{permission:permission}).then(
            response =>{
                return response.data
            }
        )
    }
    async deleteUserPermission(id){
        return await api.post('/delete-permissions',{id:id}).then(
            response =>{
                return response.data
            }
        )
    }
    async getPermissions(){
        return await api.get('/permissions').then(
            response =>{
                return response.data
            }
        )
    }
    async updateUserPermissions(permissions, id){
        return await api.post('/update_permissions',{
            permissions: permissions,
            user_id:id
        }).then(
            response =>{
                return response.data
            }
        )
    }
    

    handleResponse(response) {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    // this.logout();
                    // location.reload(true);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        });
    }


}

export default new UserService